import * as React from "react";
// import {
//     button
// } from "./button.module.scss";

const ButtonComponent = ({
    kind="flat", 
    prefix, 
    sufix, 
    text, 
    style, 
    color,
    taille,
    action}) => {

    return(
        <button
            taille={taille}
            color={color}
            kind={kind}
            aria-label={text}
            style={style}
            onClick={() => action()}
        >
            <img src={prefix} alt="" />         
            <span>
                {text}
            </span>
            <img src={sufix} alt="" />         
        </button>
    );
};

export default ButtonComponent;
