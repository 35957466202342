import * as React from "react";
import {
    blocParitel,
    logo,
    description,
    socialLogos,
    copyrightLegals,
    copyright,
    legals
} from "./footer.module.scss";
import FooterNavbarComponent from "../footer-navbar/footer-navbar.component";
import { useStaticQuery, graphql } from 'gatsby';

const FooterComponent = () => {

    const footerData = useStaticQuery(graphql`
        query footerQuery {
            cms {
                parametre {
                    data {
                        attributes {
                            footerCopyright
                            footerText
                            logo {
                                data {
                                    attributes {
                                        url
                                        alternativeText
                                    }
                                }
                            }
                        }
                    }
                }
                menus(filters: {Nom: {in: ["footer_legals","footer","footer_socials"]}}) {
                    data {
                      attributes {
                        Nom
                        items {
                          Nom
                          url
                          icone {
                            data {
                              attributes {
                                alternativeText
                                url
                              }
                            }
                          }
                          SousMenus {
                            nom
                            url
                          }
                        }
                      }
                    }
                }
            }
        }
    `);

    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className={blocParitel}>
                        <div className={logo}>
                                <div>
                                    <img src="https://res.cloudinary.com/yzy-paritel/image/upload/v1640713122/store_locator/paritel_logo_elhr3u.svg" 
                                        alt={footerData.cms.parametre.data.attributes.logo.data.attributes.alternativeText} />
                                </div>
                        </div>
                        <div className={socialLogos}>
                            {
                                footerData.cms.menus.data.find( m => m.attributes.Nom === 'footer_socials').attributes.items.map(
                                    (item,i) => (
                                        item?.icone?.data ? 
                                        <a key={"footer-socials-"+i} href={item.url}>
                                            <img src={item.icone?.data?.attributes?.url} width="32" height="32" alt={item.Nom}/>
                                        </a> : null
                                    )
                                )
                            }
                        </div>
                        <div className={description}>
                            <p>
                                {footerData.cms.parametre.data.attributes.footerText}
                            </p>
                        </div>
                    </div>
                    <FooterNavbarComponent/>
                </div>
                <div className="row">
                    <div className={copyrightLegals}>
                        <div className={copyright}>
                            {footerData.cms.parametre.data.attributes.footerCopyright}
                        </div>
                        <div className={legals}>
                            <ul>
                                {
                                    footerData.cms.menus.data.find( m => m.attributes.Nom === 'footer_legals').attributes.items.map(
                                        (item,i) => (
                                            <li key={"footer-legals-"+i} >
                                                {i > 0 ? " | " : ""}
                                                <a href={item.url} >
                                                    {item.Nom}
                                                </a>
                                            </li>
                                        )
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default FooterComponent;
