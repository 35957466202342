import React, { PureComponent } from "react";
import { string } from "prop-types";
import blurUp from "./progressive-image.component.scss";

export default class ProgressiveImageComponent extends PureComponent {
    static propTypes = {
        src: string.isRequired,
        alt: string.isRequired,
        className: string,
        sizes: string,
        fixedWidth: string,
        transformation: string,
        width: string,
        height: string
    }

    render() {
        const { src, transformation, alt, className, sizes, fixedWidth, height, width } = this.props;
        if(!src || src === '') return null;
        const t = transformation ? transformation.replaceAll(',','%2C')+"/":"";
        const i50 = src.replace('/upload/','/upload/'+t+'c_scale%2Cw_50/f_auto/');

        if(fixedWidth) {
            const iFixed = src.replace('/upload/','/upload/'+t+'c_scale%2Cw_'+fixedWidth+'/f_auto/');
            return (
                <img
                    src={i50}
                    sizes={sizes?sizes:fixedWidth+"px"}
                    srcSet="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    data-srcset={iFixed+" "+fixedWidth+"w"}
                    data-src={iFixed}
                    className={"lazyload "+blurUp+" "+className}
                    height={height}
                    width={width}
                    alt={alt}/>
            )
        }

        const i320 = src.replace('/upload/','/upload/'+t+'c_scale%2Cw_320/f_auto/');
        const i640 = src.replace('/upload/','/upload/'+t+'c_scale%2Cw_640/f_auto/');
        const i940 = src.replace('/upload/','/upload/'+t+'c_scale%2Cw_940/f_auto/');
        const i1600 = src.replace('/upload/','/upload/'+t+'c_scale%2Cw_1600/f_auto/');
        const i3200 = src.replace('/upload/','/upload/'+t+'c_scale%2Cw_3200/f_auto/');

        return (
            <img
                src={i50}
                sizes={sizes?sizes:"auto"}
                srcSet="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                data-srcset={i320+" 320w,"+i640+" 640w,"+i940+" 940w,"+i1600+" 1600w,"+i3200+" 3200w"}
                data-src={i320}
                className={"lazyload "+blurUp+" "+className}
                height={height}
                width={width}
                alt={alt}/>
        )
    }
}