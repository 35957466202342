import * as React from "react";
// import 'normalize.css'; // Note this
import {
    container,
} from "./layout.module.scss";
import CookieBanner from "components/cookie-banner/cookie-banner.component.js";
import FooterComponent from "components/footer/footer.component.js";
import HeaderComponent from "components/header/header.component";
import Helmet from "react-helmet";
import { useStaticQuery, graphql} from "gatsby";
import ModalComponent from "../components/modal/modal.component";

const Layout = ({children}) => {
    const siteData = useStaticQuery(
        graphql`
            query sitesQuery {
                cms {
                sites(filters: {title: {eq:"Storelocator"}}) {
                    data {
                        attributes {
                            url
                            title
                            favicon {
                                data {
                                    attributes {
                                        url
                                        alternativeText
                                    }
                                }
                            }
                            accrocheTitre
                            accrocheSoustitre
                            baniere {
                                data {
                                    attributes {
                                        alternativeText
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
                }
            }
        `
    )

    return (
        <div className={container}>
            <HeaderComponent siteData={siteData.cms.sites.data[0].attributes}></HeaderComponent>
            <main>
                <div className={container}>
                    {children}
                </div>
                <ModalComponent/>
            </main>
            <FooterComponent/>
            <CookieBanner/>
        </div>
    )
}

export default Layout;