import * as React from "react";
import {graphql, Link, StaticQuery} from "gatsby";
import {
    primaryNavigation,
    navLinks,
    logo,
    burgerMenu,
    burgerMenuIcon,
    closeMenu,
    closeMenuIcon,
    active
} from "./navbar.module.scss";
import ProgressiveImageComponent from "../progressive-image/progressive-image.component";

function showMenu(e) {
    e.preventDefault();
    let menu = document.querySelector('nav');
    menu.classList.add(active);
}

function hideMenu(e) {
    e.preventDefault();
    let menu = document.querySelector('nav');
    menu.classList.remove(active);
}


const NavbarComponent = () => {
    return (
        <StaticQuery
        query={graphql`
        query navQuery {
            cms {
              menus(filters: {Nom: {eq: "store_locator_header"}}) {
                data {
                  attributes {
                    Nom
                    items {
                      Nom
                      url
                      SousMenus {
                        nom
                        url
                      }
                    }
                  }
                }
              }
            }
        }
        `}
        render={ (data)  => (
            <nav className={primaryNavigation}>
            <div className={logo}>
                <Link to="/">
                    <ProgressiveImageComponent
                        fixedWidth="140"
                        width={"140"}
                        src="https://res.cloudinary.com/yzy-paritel/image/upload/v1640713122/store_locator/paritel_logo_elhr3u.svg"
                        alt="Logo Paritel"/>
                </Link>
            </div>
            <ul className={navLinks}>
                <li className={closeMenu}>
                    <button aria-label="Fermer" className="close_button" onClick={hideMenu}>
                        <span className={closeMenuIcon}/>
                    </button>
                </li>
                {
                    (data.cms.menus.data.length > 0 ? 
                    data.cms.menus.data[0].attributes.items:
                    []).map(
                        (item,i) => (
                            <li key={"nav-"+i}>
                                <a href={item.url} target="_blank">{item.Nom}</a>
                            </li>        
                        )
                    )
                }
            </ul>
            <div className={burgerMenu}>
                <button aria-label="Ouvrir menu" className="burger_button" onClick={showMenu}>
                    <span className={burgerMenuIcon}/>
                </button>
            </div>
        </nav>
        )}
        />
    )
}

export default NavbarComponent;
