import * as React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";

const ModalComponent = ({}) => {
  let [isShowing, setShowing] = useState(false);

  const closeOnEscape = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      setShowing(false);
    }
  };

  const openModal = React.useCallback(() => setShowing(true));

  React.useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscape);
    document.addEventListener("modal:open", openModal);

    return () => {
      document.body.removeEventListener("keydown", closeOnEscape);
      document.removeEventListener("modal:open", openModal);
    };
  }, []);

  return (
    <div
      className={`modal ${isShowing ? "show" : ""}`}
      onClick={() => setShowing(false)}
    >
      <div
        className="modal-window"
        onClick={(e) => e.stopPropagation()}
        style={{ display: isShowing ? "block" : "none" }}
      >
        <div className="modal-header">
          <button
            aria-label="Fermer"
            className="modal-close"
            aria-label="Fermer"
            onClick={() => setShowing(false)}
          >
            ×
          </button>
          <div className="modal-title">
            DEMANDEZ UN BILAN GRATUIT DE VOS BESOINS DE COMMUNICATION
          </div>
          <div className="modal-container">
            <form
              id="sb_form"
              onsubmit="process2(
   'https://api.sarbacane.com/v1/forms/contacts/upsert?listID&#x3D;92a5dee8-8426-4ff4-a2c3-07fdc4ff8603&amp;formID&#x3D;C9gaJeFwROaWxLgaA3tMCA&amp;timezone&#x3D;Europe/Paris', 'https://forms.sbc33.com/', '646222f8ba35bc4f19633e13', 'false', 'message',
   '',
   'https://api.sarbacane.com/v1/transactional/sendmessage/optin', 'Merci', 'Vos informations ont été ajoutées avec succès.',
   'Vous allez recevoir un email', 'Vous devrez cliquer sur le lien de confirmation pour valider votre inscription', 'Erreur',
   'Une erreur inattendue s%27est produite.', 'Le formulaire est en cours d%27édition, veuillez patienter quelques minutes avant d%27essayer à nouveau.', '',
   '', '', '', ''
 );
 return false;"
              method="post"
            >
              <span style="display: flex; flex-direction: row">
                <label id="label-495845d3-7c3a-477b-86c5-f46164e596e3">
                  Pr&eacute;nom
                </label>
                <div id="form-mandatory">*</div>
              </span>
              <input
                id="input-495845d3-7c3a-477b-86c5-f46164e596e3"
                type="text"
                name="495845d3-7c3a-477b-86c5-f46164e596e3"
                required="true"
                sb-form-input
              />

              <span style="display: flex; flex-direction: row">
                <label id="label-633ccf06-5280-4445-bc1d-20b490959b21">
                  Nom
                </label>
                <div id="form-mandatory">*</div>
              </span>
              <input
                id="input-633ccf06-5280-4445-bc1d-20b490959b21"
                type="text"
                name="633ccf06-5280-4445-bc1d-20b490959b21"
                required="true"
                sb-form-input
              />

              <span style="display: flex; flex-direction: row">
                <label id="label-8c639bf9-9090-4ece-98fe-a64bfd2fbf49">
                  Raison Sociale
                </label>
                <div id="form-mandatory">*</div>
              </span>
              <input
                id="input-8c639bf9-9090-4ece-98fe-a64bfd2fbf49"
                type="text"
                name="8c639bf9-9090-4ece-98fe-a64bfd2fbf49"
                required="true"
                sb-form-input
              />

              <span style="display: flex; flex-direction: row">
                <label id="label-af8e4564-b69d-4695-82d4-8a11bd9df2f3">
                  Code Postal
                </label>
                <div id="form-mandatory">*</div>
              </span>
              <input
                id="input-af8e4564-b69d-4695-82d4-8a11bd9df2f3"
                type="number"
                name="af8e4564-b69d-4695-82d4-8a11bd9df2f3"
                required="true"
                sb-form-input
              />

              <span style="display: flex; flex-direction: row">
                <label id="label-email">Email</label>
                <div id="form-mandatory">*</div>
              </span>
              <input
                id="input-email"
                type="email"
                name="email"
                required="true"
                sb-form-input
              />

              <span style="display: flex; flex-direction: row">
                <label id="label-phone">
                  N&deg; de t&eacute;l&eacute;phone
                </label>
                <div id="form-mandatory">*</div>
              </span>
              <input
                pattern="[+]?[\d\s.\-\(\)]{10,}"
                id="input-phone"
                type="tel"
                name="phone"
                required="true"
                sb-form-input
              />

              <span style="display: flex; flex-direction: row">
                <label id="label-6b939f17-95a9-4179-bf08-f1cdda69ea14">
                  accepte-com
                </label>
              </span>
              <div id="div-6b939f17-95a9-4179-bf08-f1cdda69ea14">
                <div style="margin-bottom: 13px; display: flex; flex-direction: row">
                  <label class="control control--checkbox">
                    <input
                      type="checkbox"
                      value="J’accepte que les informations saisies soient exploitées dans le cadre de la demande de contact.*"
                      name="6b939f17-95a9-4179-bf08-f1cdda69ea14"
                      sb-form-input
                    />
                    <div
                      id="indicator-6b939f17-95a9-4179-bf08-f1cdda69ea14"
                      class="control__indicator"
                    ></div>
                  </label>
                  <label id="label-opt-6b939f17-95a9-4179-bf08-f1cdda69ea14">
                    J’accepte que les informations saisies soient exploitées
                    dans le cadre de la demande de contact.*
                  </label>
                </div>
                <div style="margin-bottom: 13px; display: flex; flex-direction: row">
                  <label class="control control--checkbox">
                    <input
                      type="checkbox"
                      value="J’accepte de recevoir par mail des offres commerciales de la part de la société Paritel."
                      name="6b939f17-95a9-4179-bf08-f1cdda69ea14"
                      sb-form-input
                    />
                    <div
                      id="indicator-6b939f17-95a9-4179-bf08-f1cdda69ea14"
                      class="control__indicator"
                    ></div>
                  </label>
                  <label id="label-opt-6b939f17-95a9-4179-bf08-f1cdda69ea14">
                    J’accepte de recevoir par mail des offres commerciales de la
                    part de la société Paritel.
                  </label>
                </div>
              </div>
              <div id="div-submitInput" align="left">
                <button
                  id="submitInput"
                  type="submit"
                  value="Valider"
                  class="mobile-FW"
                >
                  <span style="margin: 0px; font-size: 17px">
                    <span style="font-size: 17px">
                      <strong style="font-size: 17px">
                        <span style="font-size: 17px">Valider</span>
                      </strong>
                    </span>
                  </span>
                </button>
                <div class="loader" style="display: none"></div>
              </div>
              <label id="form-footer-mandatory">* Champs obligatoires</label>
            </form>
            <p style="font-size: 10px">
              Conformément à nos obligations légales, vous pouvez accéder aux
              données communiquées, les rectifier, demander leur effacement ou
              exercer votre droit à la limitation du traitement de vos données.
              Pour exercer ces droits, vous pouvez vous adresser au DPO Paritel
              à l’adresse suivante : DPO@paritel.fr. Enfin, vous disposez
              également d'un accès à notre politique de confidentialité via le
              lien suivant :
              https://www.paritel.fr/politique-de-confidentialite/
            </p>

            <Helmet>
              <script
                async
                type="text/javascript"
                src="https://forms.sbc30.net/form.js"
              ></script>
            </Helmet>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
