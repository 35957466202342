import React, {Component} from "react";
import {
    scrollTopContainer,
    scrollTopButton
} from './scroll-top.module.scss';
import iconArrowUp from 'images/icon-arrow-up.svg';

export default class ScrollTopComponent extends Component {

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const scrollTopContainer = document.getElementById('scrollTopContainer');
        if(scrollTopContainer) {
            if (770 - window.scrollY > 36) {
                scrollTopContainer.style.top = `${770 - window.scrollY}px`;
            } else {
                scrollTopContainer.style.top = '36px';
            }
        }
    }

    render() {

        const scrollBackToTop = () => {
            window.scroll({
                top: 0
            });
        }

        return (
            <div id="scrollTopContainer" className={scrollTopContainer}>
                <button aria-label="Scroll top" id="scrollTopButton" title="Scroll Top Button" className={scrollTopButton} onClick={() => scrollBackToTop()}>
                    <img src={iconArrowUp} alt="go top"/>
                </button>
            </div>
        );
    }
}
