import React, { useRef, useEffect, useState } from "react";
import ButtonComponent from "../button/button.component";
import {
	text,
	buttons,
	cookieBanner
} from './cookie-banner.module.scss';
import Cookies from 'js-cookie';

export const setUserConsent = (consent) => {
	Cookies.set('sl-user-consent', consent, {
		expires: 365,
		secure: true,
		sameSite: true
	});
	Cookies.set('sl-user-consent-legacy', consent, {
		expires: 365,
		secure: true
	});
	document.dispatchEvent(new CustomEvent('user-consent-change',{}));
	if(window.dataLayer) {
		window.dataLayer.push({
			event: 'user-consent-change'
		});
	}
}

export const getUserConsent = () => {
	let consent = Cookies.get('sl-user-consent');
	if(consent === undefined) {
		consent = Cookies.get('sl-user-consent-legacy');
	}
	return consent;
}

export const isUserConsent = () => {
	const consent = getUserConsent();
	return consent && consent == 'accepted';
}

const CookieBannerComponent = () => {
	const cookieBannerRef = useRef(null);
	const [consent, setConsent] = useState(false);
	// const settings = () => {};
	const cancel = () => {
		setUserConsent('refused');
		setConsent(isUserConsent());
	};

	const accept = () => {
		setUserConsent('accepted');
		setConsent(isUserConsent());
	};

	useEffect(() => {
		setConsent(getUserConsent());
		window['StoreLocatorCookieConsent'] = isUserConsent ? 'accepted':'';
	}, []);

    return ( !consent ? 
        	<div className={cookieBanner} ref={cookieBannerRef}>
        		<div className="container">
        			<div className="row align-items-center">
			            <div className={text}>En poursuivant, vous acceptez l’utilisation de cookies pour vous proposer des services et offres adaptés à vos centres d’intérêts.</div>
			            <div className={buttons}>
							{/*<ButtonComponent kind="outline" text="Réglages" action={settings} />*/}
							<ButtonComponent kind="outline" color="default" text="Refuser" action={cancel} />
							<ButtonComponent kind="flat" text="Accepter" action={accept} />
				        </div>
				    </div>
		        </div>
            </div>
			: null
    );
};

export default CookieBannerComponent;
