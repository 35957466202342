import * as React from "react";
import { Helmet } from "react-helmet";
import BannerComponent from "../banner/banner.component";
import NavbarComponent from "../navbar/navbar.component";
import ScrollTopComponent from "../scroll-top/scroll-top.component";
import SearchComponent from "../search/search.component";
import {
    introBackground
} from './header.module.scss';

const HeaderComponent = ({siteData}) => {
	const url = siteData.baniere.data.attributes.url.replace('/upload','/upload/w_1920,f_auto')
    return (
        <header>
	        <div className={introBackground} style={{backgroundImage: 'url('+url+')'}}>
		        <div className="container">
		            <NavbarComponent/>
		            <BannerComponent bannerContent={siteData}/>
		            <SearchComponent/>
					<ScrollTopComponent/>
		        </div>
	        </div>
	    </header>
    )
}

export default HeaderComponent;