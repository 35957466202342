import './src/styles/styles.scss';
import * as React from "react";
import Layout from "./src/templates/layout.template";
import 'lazysizes';


export const wrapPageElement = ({element, props}) => {
    return <Layout {...props}>
        {element}
    </Layout>
}

export const onRouteUpdate = ({location, prevLocation}) => {

    if(!prevLocation) return;

    if(location.pathname === '/') {
        setTimeout( () =>
        window.scroll({
            top: 0
        }), 200);
    }
    else {
        setTimeout( () => {
            const header = document.getElementsByTagName("header");
            if(header.length === 1) {
                window.scroll({
                    top: header[0].offsetHeight
                })
            }
        }, 100);
    }
}

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
  }) => {  
    return false
  }