import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
    bannerText,
    titre
} from "./banner.module.scss";

const BannerComponent = ({ bannerContent }) => {
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);

    useEffect(() => {
        setTitle(bannerContent.accrocheTitre);
        setDescription(bannerContent.accrocheSoustitre);
    }, [])

    document.addEventListener('update:seoData', (e) => {
        setTitle(e.detail.title);
        setDescription(e.detail.description);
    });

    const buildTitle = () => {
        if (document.location.pathname === '/') {
            return (
                <h1 className={titre}>
                    {
                        title
                    }
                </h1>
            );
        }

        return (
            <h2 className={titre}>
                {
                    title
                }
            </h2>
        );
    }

    return (
        <div className={bannerText}>
            {
                buildTitle()
            }
            <p>
                {
                    description
                }
            </p>
        </div>
    );
}

export default BannerComponent;
