import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql, navigate } from "gatsby";
import {
    searchContainer,
    searchBackground,
    searchInputContainer,
    iconButton,
    iconSearchButton,
    vDivider,
    btnWrapper,
    resultsList,
    active
} from "./search.module.scss";
import iconSearch from 'images/icon-search.svg';
import iconClose from 'images/icon-close.svg';
import iconMyLocation from 'images/icon-my-location.svg';
import { MAPBOX_ACCESS_TOKEN, MAPBOX_COUNTRY, MAPBOX_LANGUAGE, MAPBOX_SESSION_TOKEN, MAPBOX_URL } from "../../utils/constants";

let delay = null;

/**
 * @type {Array}
 */
const SearchComponent = () => {


    const searchInputPlaceholder = 'Ville, Code Postal de l’agence la plus proche';
    const [search, setSearch] = useState("");
    const [features, setFeatures] = useState([]);
    const [isSelected, setIsSelected] = useState(false);
    const [displayList, setDisplayList] = useState('none');
    const searchInputRef = useRef();
    const searchBackgroundRef = useRef();
    let departments;

    const location = useLocation();

    useEffect( () => {
        if(location.state && location.state.selectedSearch) {
            setSearch(location.state.selectedSearch);
            setIsSelected(true);    
        } else {
            setSearch('');
            setIsSelected(false);
        }
    }, [location]);

    const query = useStaticQuery(
        graphql`
        query departementsQuery {
            cms {
                departements(pagination: {pageSize: 100}) {
                    data {
                        id
                        attributes {
                            slugDepartement
                            slugRegion
                            region
                            code
                            nom
                            agences {
                                data {
                                  attributes {
                                    slug
                                    latitude
                                    longitude
                                  }
                                }
                          }
                        }
                    }
                }
            }
        }
        `
    );

    departments = query.cms.departements.data;

    useEffect(() => {
        const baseURL = MAPBOX_URL;

        const queryParams = {
            "access_token": MAPBOX_ACCESS_TOKEN,
            "session_token": MAPBOX_SESSION_TOKEN,
            "language": MAPBOX_LANGUAGE,
            "country": MAPBOX_COUNTRY,
            "autocomplet": true,
            "fuzzyMatch": true,
            "limit": 3
        };

        if (search === '') {
            searchBackgroundRef.current.classList.remove(active);
            setDisplayList('none');
            return;
        }

        if(isSelected) {
            searchBackgroundRef.current.classList.remove(active);
            setDisplayList('none');
            return;
        }

        if (delay != null) {
            clearTimeout(delay);
        }

        // Debounce the time between keyboard strokes
        delay = setTimeout(async () => {
            const url = new URL(`${baseURL}${search}.json`);
            Object.keys(queryParams).forEach(k => url.searchParams.append(k, queryParams[k]));

            const body = await fetch(url).then(response => response.json());
            setFeatures(body.features);
            if (features.length > 0) {
                setDisplayList('block');
                searchBackgroundRef.current.classList.add(active);
            } else {
                searchBackgroundRef.current.classList.remove(active);
                setDisplayList('none');
            }
        }, 200);
    }, [search, isSelected]);

    let selectedCenter, selectedSearch, selectedFeature, resultType, searchType;

    const setSelection = (search, center, feature) => {
        selectedSearch = search;
        selectedCenter = center;
        selectedFeature = feature;
    }

    const gtmEvent = () => {
        if(window.dataLayer) {
            window.dataLayer.push({
                event: "storelocator-search",
                search: selectedSearch,
                resultType: resultType,
                searchType: searchType
            });
        }
    }

    const onItemClick = async (item) => {
        setSearch(item.place_name_fr);
        setIsSelected(true);
        setDisplayList('none');
        setSelection(item.place_name_fr, item.center, item);
        searchBackgroundRef.current.classList.remove(active);

        (await goToRegion(item)) ||
        (await goToDepartement(item)) ||
        (await goToAddress(item)) ||
        (await goToLonLat(item.center[0],item.center[1], false)) ||
        (goToNoResults());
    }

    const goToNoResults = () => {
        resultType = 'no-results';
        goTo('/no-results');
    }

    const goToRegion = async(item) => {
        if (item.place_type.indexOf('region') !== -1) {
            const result = departments.find(departement => departement.attributes.region.toLowerCase() === item?.text_fr.toLowerCase());
            if(result) {
                resultType = 'region';
                searchType = 'region';
                goTo(`/${result?.attributes.slugRegion}`);
                return true;
            }
        }
    }

    const goToDepartement = async(item) => {

        if (item.place_type.indexOf('region') !== -1) {
            const result = departments.find(departement => departement.attributes.nom.toLowerCase() === item?.text_fr.toLowerCase());
            if(result) {
                searchType = 'departement';
                await goToDepartementOrAgency(result, item.center);
                return true;
            }
        }
    }

    const distanceKm = (lat1, lon1, lat2, lon2) => {
        var p = 0.017453292519943295;    // Math.PI / 180
        var c = Math.cos;
        var a = 0.5 - c((lat2 - lat1) * p)/2 +
          c(lat1 * p) * c(lat2 * p) *
          (1 - c((lon2 - lon1) * p))/2;
      
        return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    }

    const goToDepartementOrAgency = async(departement, point) => {
        
        let nearestAgency = null, dMin = 20000;


        if(point) {
            departement.attributes.agences.data.forEach(agence => {
                const d = distanceKm(agence.attributes.latitude, agence.attributes.longitude, point[1], point[0]);
                if(d < dMin) {
                    nearestAgency = agence;
                    dMin = d;
                }
            });    
        }
        if(departement.attributes.agences.data.length && !nearestAgency) {
            nearestAgency = departement.attributes.agences.data[0];
        }

        if(dMin < 10) {
            resultType = 'agency-less-10km';
            return goTo(`/`+nearestAgency.attributes.slug);
        } else if(departement.attributes.agences.data.length === 1) {
            resultType = 'agency-only-one';
            return goTo(`/`+nearestAgency.attributes.slug);
        } else if(departement.attributes.agences.data.length > 1) {
            resultType = 'departement-agencies-list';
            return goTo(`/${departement.attributes.slugRegion.toLowerCase()}/${departement.attributes.slugDepartement.toLowerCase()}`);
        }
    }

    const goToAddress = async (item) => {
        if (item.place_type.indexOf('address') !== -1 || item?.place_type.indexOf('poi') !== -1) {
            searchType = 'address';
            const code = item.context.find(item => item.id.substring(0, 8) === 'postcode')?.text_fr?.substring(0, 2);
            const result = departments.find(departement => departement.attributes.code === code);
            if(result) {
                return await goToDepartementOrAgency(result, item.center);
            }
        }
    }

    const goToLonLat = async (long, lat, select) => {
        const queryParams = {
            "access_token": MAPBOX_ACCESS_TOKEN,
            "session_token": MAPBOX_SESSION_TOKEN
        };
        const url = new URL(`${MAPBOX_URL}${long},${lat}.json`);
        Object.keys(queryParams).forEach(k => url.searchParams.append(k, queryParams[k]));

        const body = await fetch(url).then(response => response.json());
        const postCode = body.features.find(item => item.place_type.indexOf('postcode')!==-1)?.text?.substring(0, 2);
        const result = departments.find(departement => departement.attributes.code === postCode);
        searchType = 'location';

        if(select) {
            searchType = 'my-location';
            const address = body.features.find(item => item.place_type.indexOf('address')!==-1)?.place_name;
            setSelection(address, [long, lat], body);
        }

        if(result) {
            return await goToDepartementOrAgency(result, [long, lat]);
        }
    }

    const clearSearch = () => {
        setIsSelected(false);
        setSearch('');
        setFeatures([]);
        setDisplayList('none');
        searchBackgroundRef.current.classList.remove(active);
    };

    const getMyLocation = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            goToLonLat(position.coords.longitude, position.coords.latitude, true);
        });
    }

    const goTo = (url) => {
        gtmEvent();
        navigate(url, {
            state: {
                selectedSearch: selectedSearch,
                selectedCenter: selectedCenter
            }
        });
        return true;
    }

    const scrollTop = () => {
        if(window.innerWidth < 450) {
            console.log(searchInputRef);
            window.scrollTo({
                top: searchInputRef.current.getBoundingClientRect().top + window.scrollY -40
            });
        }
    }

    return (
        <div className={searchContainer}>
            <div ref={searchBackgroundRef} className={searchBackground}>
                <div>
                    <div className={searchInputContainer}>
                        <input ref={searchInputRef} value={search}
                               onFocus={() => scrollTop()}
                                onChange={e => setSearch(e.target.value)}
                                onKeyDown={e => {setIsSelected(false);setSearch(e.target.value)}}
                            placeholder={searchInputPlaceholder} />
                    </div>
                    <div className={btnWrapper}>
                        {
                            isSelected ?
                                <button className={iconButton}
                                    aria-label="effacer recherche"
                                    onClick={() => clearSearch()}>
                                    <img src={iconClose} alt="Close" />
                                </button>
                                :
                                <button className={iconButton}
                                aria-label="Me localiser"
                                onClick={() => getMyLocation()}>
                                    <img src={iconMyLocation} alt="Me localiser" />
                                </button>
                        }
                        <div className={vDivider}></div>
                        <div className={iconSearchButton} onClick={()=> onItemClick(features[0])}>
                            <img src={iconSearch} alt="Search" />
                        </div>
                    </div>
                </div>
            </div>
            <ul className={resultsList} display={displayList}>
                {
                    features?.map((item, index) => (SearchItem(index, item, (item) => onItemClick(item))))
                }
            </ul>
        </div>
    )
}

export default SearchComponent;

const SearchItem = (key, item, f) => {
    return (
        <li key={key} onClick={() => f(item)}>
            {item.place_name_fr}
        </li>
    );
};