import * as React from "react";
import {
    categories,
    categoriesContainer,
    categoriesItem,
    footerNavItem,
    categoriesItemTitle
} from "./footer-navbar.module.scss"
import { useStaticQuery, graphql } from 'gatsby';

const FooterNavbarComponent = () => {
    const footerData = useStaticQuery(graphql`
        query footerNavBarQuery {
            cms {
                menus(filters: {Nom: {in: ["footer_legals","footer"]}}) {
                    data {
                      attributes {
                        Nom
                        items {
                          Nom
                          url
                          icone {
                            data {
                              attributes {
                                alternativeText
                                url
                              }
                            }
                          }
                          SousMenus {
                            nom
                            url
                          }
                        }
                      }
                    }
                }
            }
        }
    `);
    

    const footer = footerData.cms.menus.data.length > 0 ? footerData.cms.menus.data[0].attributes.items : [];

    return (
        <div className={categories}>
            <div className={categoriesContainer}>
                {footer.map( (item,j) => (
                    <div className={categoriesItem} key={"footer-nav-column-"+j}>
                        <div className={categoriesItemTitle}>{item.Nom}</div>
                        <ul>
                        {
                            item.SousMenus.map( (subitem,i) => (
                            <li key={"footersubmenu-"+i}><a target="_blank" key={"footer-nav-"+i} href={subitem.url}>{subitem.nom}</a></li>
                            ))
                        }
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FooterNavbarComponent;
